 .partner-bg{
    background-image: url("../../../../public/Img/Img/bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
 }

 .p-bg{
    background-color: #E0F6FF;
    width: 100%;
    height: 100%;
 }

 @media screen and (max-width: 500px){
    .part-h{
        font-size: 20px;
        width: 100%;
        line-height: normal;
        margin-top: 50px;
    }

    .ppl{
        margin-top: 0px;
    }

    .sm{
        font-size: 18px;
    }

    .p-bg{
        padding-right: 0px;
        padding-bottom: 20px;
    }

    .sec-h{
        padding: 0px;
        width: 100%;
    }

    .section-3{
     text-align: center;
     padding: 0px;
     margin-left: 0px !important;
     font-size: 30px;
    }

    .section-2{
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }
 }