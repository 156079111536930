span > .text-1 {
  color: #10154e !important;
  overflow: hidden;
}

.exp-w {
  width: 100% !important;
  overflow: hidden;
}

.exp-p{
  overflow: hidden;
}

.exp-t{
  overflow: hidden;
  font-weight: 500;
}

.exp-year{
  overflow: hidden;
}

.x-p{
  overflow: hidden;
}

.deadline {
  width: 70%;
  overflow: hidden;
}

.line-red {
  width: 2px;
  height: 60px;
  background-color: red;
  overflow: hidden;
}

.line-red-1{
  height: 100px;
}

@media screen and (max-width: 500px) {
  .exp-t {
    font-size: 20px;
    width: 100%;
    margin-top: 40px;
    text-align: center;
    overflow: hidden;
  }

  .exp-p {
    font-size: 16px !important;
    width: 100% !important;
    padding: 0px !important;
    margin-top: 1rem;
    text-align: center;
  }

  .line-red-1 {
    margin-top: 1rem !important;
    height: 100px !important;
    margin-left: 10px;
    background: none;
    margin-right: 0px;
  }

  .deadline {
    text-align: center;
    width: 100% !important;
  }

  .red-logo {
    display: none;
  }

  .exp-year {
   
  }

  .x-p {
    margin-top: 2rem !important;
  }
}

@media screen and (max-width: 360px){
 .exp-t{
  margin-top: 50px;
 }
 .line-360{
  margin-right: 0px !important;
 }

}

@media screen and (max-width: 390px) {
  .exp-t {
    font-size: 20px;
    margin-top: 40px;
    text-align: center;
    overflow: hidden;
  }

  .exp-p {
    font-size: 16px !important;
    width: 100% !important;
    padding: 0px !important;
    margin-top: 1rem;
    text-align: center;
  }

  .line-red-1 {
    margin-top: 1rem !important;
    height: 120px !important;
    margin-right: 0px;
    margin-left: 0px;
  }

  .deadline {
    text-align: center;
    width: 100% !important;
  }

  .red-logo {
    display: none;
  }

  .exp-year {
   
  }

  .x-p {
    margin-top: 2rem !important;
  }
}

@media screen and (max-width: 375px) {
}

@media screen and (max-width: 414px) {

}
