.img2{
      width: 500px;
      height: 500px;
}

.cre-h1{
    font-weight: 500;
}


.line-w{
    height: 130px;
}

.creative-bg{
    background: #F3F4FF;
    padding-bottom: 5rem;
    width: 100%;
    height: 100%;
}

.more{
    background-color: rgba(214, 33, 33, 0.921);
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.logo-w{
    width: 800px !important;
}

@media screen and (max-width: 500px) {
    .creative-bg{
        width: 100% !important;
    }

    .img-flex{
        display: block;
        margin-left: 0px;
        margin-right: 0px;
    }

    .eje{
       padding-left: 20px;
       margin-bottom: 20px;
    }

    .eje2{
        padding-right: 20px;

    }

    .logo-des{
        margin-right: 50px !important;
        margin-left: 50px !important;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .creative-img{
        display: flex;
        margin-right: 40px;
    }

    .cre-2{
        display: block !important;
    }

    .cre-h1{
        overflow: hidden;
        width: 100%;
        font-size: 20px;
        text-align: center;
        margin-left: 0;
        margin-top: 20px;
    }

    .line-w{
        margin-top: 4rem;
        margin-left: 0px;
    }

    .creative-p p{
        margin-top: 25px;
        width: 100%;
    }
}

@media screen and (max-width: 360px) {
    .cre-h1{
        margin-left: 0;
        font-size: 20px;
        text-align: center;
        margin-top: 50px;
    }

    .line-w{
        margin-left: 0px;
    }

    .logo.des{
        margin-left: 0px;
    }
}

@media screen and (max-width: 390px){
    .cre-h1{
        font-size: 20px;
    }
}


