.case-2{
    position:absolute;
    top: 0 !important;
    left: 400px;
}

.hero-text-sm{
   overflow: hidden;
}

.hero-h1{
   font-weight: 400;
}

.vid{
   overflow: hidden;
}

@media screen and (max-width: 500px) {
 .hero-text-sm{
    margin-top: 9rem !important;
    overflow-x: hidden;
 }
 .hero-text-sm2{
    margin-top: 0px !important;
    font-size: 12px !important;
    overflow-x: hidden;
 }
 .hero-p{
    font-size: 12px !important;
    margin-bottom: 10px !important;
    overflow-x: hidden;
 }
 .hero-h1{
     font-size: 50px !important;
     overflow: hidden;
 }
 .hero-b{
   display: block !important;
   overflow: hidden;
 }
}

@media screen and (max-width: 390px){
.hero-text-sm{
    margin-top: 9rem !important;
    overflow-x: hidden;
 }
 .hero-text-sm2{
    margin-top: 0px !important;
    font-size: 12px !important;
    overflow-x: hidden;
 }
 .hero-p{
    font-size: 12px !important;
    margin-bottom: 10px !important;
    overflow-x: hidden;
 }
 .hero-h1{
     font-size: 50px !important;
     overflow: hidden;
 }
 .hero-b{
   display: block !important;
   overflow: hidden;
 }
}

@media (min-width: 1024px){
.lg\:leading-tight {
    line-height: 1.25;
    overflow:hidden !important;
   }
}


