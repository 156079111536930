.line{
    width: 100%;
    height: 1px;
    background-color: red;
}

.footer-bg{
    background: #10154E;
    height: 100%;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 75px;
}

@media screen and (max-width: 500px){
    .footer-bg{
        width: 100% !important;
        margin-top: 0px;
        padding-top:50px;
        padding-bottom: 45px;
    }

    .footer-items{
        display: block !important;
        justify-content: center !important;
        align-items: center;
        width: 100% !important;
        text-align: center;
    }

    .email{
        display: flex;
        margin-left: auto;
    }

    .text-white-2{
        width: 100%;
        overflow: hidden;
        font-size: 30px;
    }


    .footer-bt{
        margin-left: 100px;
        margin-bottom: 50px;
    }


    .f-p{
        font-size: 14px !important;
    }
}

@media screen and (max-width: 360px){
    .footer-bt{
        margin-left: 0px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 390px){
    .footer-bt{
        margin-left: 0px;
    }
}


