


@media screen and (max-width: 500px){
    .cre-h1{
        margin-top: 0px !important;
        
    }
  }

  @media screen and (max-width: 414px){
    .cre-h1{
        font-size: 20px !important;
    }
  }
