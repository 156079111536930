.about-line{
    width: 10%;
    margin-left: 45%;
}

.team-h1{
    font-weight: 500;
}

.about-t{
    font-weight: 500;
}

.abt-h1{
    font-weight: 500;
}

.about-des{
    padding-left: 7rem;
    padding-right: 7rem;
}

.tt{
    padding-left: 7rem;
    padding-right: 7rem;
}

.process-h1{
    font-weight: 500;
}

.we-do-h1{
    font-weight: 500;
    width: 100%;
}

.w-1{
    height: 90px;
}

.bg-02{
    background-color: #F3F4FF;;
}
.bg-cool{
     background-color: #F3F4FF;;
}
.line2{
    width: 10%;
}

.flex1{
    display: flex !important;
}

@media screen and (max-width: 500px){
    .about-t{
        text-align: center;
        width: 100% !important;
        font-size: 25px !important;
        overflow: hidden;
        margin-left: 0px;
        margin-top: 95px;
    }

    .team-h1{
        font-size: 25px;
        margin-left: 60px;
        margin-bottom: 0px;
    }

    .abt-h1{
        font-size: 25px;
    }

    .w-1{
        margin-top: 0;
        margin-left: 0px;
    }
    
    .about-p{
        width: 100% !important;
        font-size: 12px !important;
        display: flex !important;
        padding: 0px;
    }

    .w-2{
        width: 20%;
    }

    .p{
        width:100% !important;
    }
    
.about-con-p{
    margin-left:0px !important;
    text-align: center;
    padding: 20px;
}

.about-des{
    display: block !important;
}

.des-p{
    width:100% !important;
    font-size: 14px !important;
}

.we-logos{
    margin-left: 0px !important;
}

.we-do-h1{
    height: auto;
    font-size: 25px;
}

.we-do{
    display: block !important;
    width: 100% !important;
}


.flex-img{
    display: flex !important;
    margin-left: 1px !important;
}

.name{
    padding-right: 50px;
}

.img-small{
    display: block !important;
    margin-left: 90px;
}

.line2{
    display: none;
}

.img-1{
    width: 100% !important;
    margin: 10px !important;
}

.me{
    width: 50% !important;
}

.process-h1{
     text-align: center;
      height: auto;
      font-size: 25px;
}

.content-1{
    display: block !important;
}

.p-01{
    width: 100% !important;
    font-size: 15px !important;
    margin-left: 0;
    margin-bottom: 0;
}

.p2, .p3, .p4{
    margin-left: 0 !important;
    margin-top: 50px;
}

.abt-02{
    margin-top: 0px;
}

.p2{
    margin-top: 0px;
}

.abt-4{
    margin-top: 40px;
}


.p-02{
   
    font-size: 18px !important;
    margin-left: 10px !important;
}

 .pp{
margin-left: 0 !important;
width: 100%;
font-size: 30px;
}

.img-p{
    margin-left: 0px !important;
}


.abt-img{
    margin-left: 50px !important;
}

.pp2{
    width: 100% !important;
   margin-top: 20px !important;
   font-size: 16px !important;
}

}

@media screen and (max-width: 360px){
.abt-h1{
    font-size: 25px !important;
}
.we-do-h1{
    font-size: 25px;
}
.flex-img{
    margin-right: 20px;
}
.team-h1{
    font-size: 25px;
}
.w-1{
    margin-right: 0px;
}
.process-h1{
    font-size: 25px;
}
.pp{
    font-size: 16px;
}
.about-con-p{
    text-align: unset;
    padding: 0px;
}
}

@media screen and (max-width: 390px){
    .w-1{
        margin-right: 0px;
        display: none;
    }

    .about-des{
        margin-top: 40px;
    }

    .tt{
        margin-top: 40px;
    }
}

@media screen and (max-width: 414px){
    .w-1{
        margin-right: 0px;
        display: none;
    }

    .about-des{
        margin-top: 40px;
    }

    .tt{
        margin-top: 40px;
    }
}


